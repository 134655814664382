<template>
  <v-app>
    <v-container
      v-if="isLoaded"
      class="bg-white h-100 py-0 d-flex justify-center"
    >
      <v-row style="max-width: 1200px">
        <v-col v-if="isSubmitted" md="12" class="px-10 py-0">
          <div class="quotation-approved my-20">
            <div class="card">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f8faf5;
                  margin: 0 auto;
                "
              >
                <i class="checkmark">✓</i>
              </div>
              <h1>Success</h1>
              <p>
                We received your approval against quotation #
                {{ quotation_detail.quotation.barcode }}<br />
                we'll be in touch shortly!<br />
                Thank You!
              </p>
            </div>
          </div>
        </v-col>
        <v-col v-else-if="isRejected" md="12" class="px-10 py-0">
          <div class="quotation-approved rejected my-20">
            <div class="card">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f36161;
                  margin: 0 auto;
                "
              >
                <i class="checkmark">&#10006;</i>
              </div>
              <h1>Success</h1>
              <p>
                We received your rejection against quotation #
                {{ quotation_detail.quotation.barcode }}<br />
                we'll be in touch shortly!<br />
                Thank You!
              </p>
            </div>
          </div>
        </v-col>
        <v-col v-else md="12" class="px-10 py-0">
          <v-layout>
            <v-flex>
              <v-img
                contain
                lazy-src="https://fakeimg.pl/1120x163"
                :src="header_footer"
              ></v-img>
            </v-flex>
          </v-layout>
          <v-layout class="my-4">
            <v-flex class="text-center"
              ><h1 class="quotation">QUOTATION</h1></v-flex
            >
          </v-layout>
          <v-layout>
            <v-flex md2 class="customer-font-bold pl-4">FULL NRIC NAME:</v-flex>
            <v-flex class="text-left customer-font-bold">
              <span v-if="owner_name && owner_name.length">{{
                owner_name
              }}</span>
              <span v-else
                ><em class="text--secondary">no full nric name</em></span
              >
            </v-flex>
          </v-layout>
          <!--  <v-layout>
            <v-flex md2 class="pl-4">Block:</v-flex>
            <v-flex class="text-left">
              <span v-if="block && block.length">{{ block }}</span>
              <span v-else><em class="text--secondary">no block</em></span>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex md2 class="pl-4">Street:</v-flex>
            <v-flex class="text-left">
              <span v-if="street && street.length">{{ street }}</span>
              <span v-else><em class="text--secondary">no street</em></span>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex md2 class="pl-4">Unit:</v-flex>
            <v-flex class="text-left">
              <span v-if="unit && unit.length">{{ unit }}</span>
              <span v-else><em class="text--secondary">no unit</em></span>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex md2 class="pl-4">Postal Code:</v-flex>
            <v-flex class="text-left">
              <span v-if="postal_code && postal_code.length">{{ postal_code }}</span>
              <span v-else><em class="text--secondary">no postal code</em></span>
            </v-flex>
          </v-layout> -->
          <v-layout>
            <v-flex md2 class="pl-4">Contact No. (Mobile):</v-flex>
            <v-flex class="text-left">
              <span v-if="contact_no && contact_no.length">{{
                contact_no
              }}</span>
              <span v-else
                ><em class="text--secondary">no contact no. (mobile)</em></span
              >
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex md2 class="pl-4">Contact No. (Alternative):</v-flex>
            <v-flex class="text-left">
              <span v-if="contact_alt_no && contact_alt_no.length">{{
                contact_alt_no
              }}</span>
              <span v-else
                ><em class="text--secondary"
                  >no contact no. (alternative)</em
                ></span
              >
            </v-flex>
          </v-layout>
          <v-layout class="my-4">
            <v-flex
              class="customer-font-bold pl-4 text-underline text-uppercase"
            >
              PROPOSED INTERIOR DESIGN &amp; RENOVATION AT {{ unit }}
              {{ block }} {{ street }} {{ postal_code }} Singapore
            </v-flex>
          </v-layout>
          <table width="100%">
            <tbody>
              <tr>
                <td
                  colspan="10"
                  height="30px"
                  valign="center"
                  class="customer-font-bold pl-4"
                >
                  {{ quotation_detail.quotation.quotation_title }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="30px" valign="center">
                  <div
                    class="pre-formatted pl-4"
                    v-html="quotation_detail.quotation.description"
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table
            v-if="quotation_detail.packages.length"
            width="100%"
            class="package-approval-line-items"
          >
            <thead>
              <tr>
                <th height="40px" width="60%">
                  <p class="m-0 py-1 px-2 custom-grey-border">
                    Package ({{ quotation_detail.packages.length }})
                  </p>
                </th>
                <th height="40px" width="10%" class="" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Quantity</p>
                </th>
                <th height="40px" width="10%" class="" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Rate</p>
                </th>
                <th height="40px" width="10%" class="" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Optional</p>
                </th>
                <th height="40px" width="10%" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Total</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, index) in quotation_detail.packages">
                <tr :key="index" :class="{ 'is-strike': row.strike }">
                  <td height="40px" width="60%">
                    <v-layout class="custom-grey-border">
                      <v-flex
                        class="text-center mx-2 py-2"
                        v-if="row.product_image"
                      >
                        <v-avatar size="80">
                          <v-img contain :src="row.product_image"></v-img>
                        </v-avatar>
                      </v-flex>
                      <v-flex class="px-2 custom-border-left" md12>
                        <v-layout class="my-1">
                          <v-flex md12 class="font-weight-500">{{
                            row.product
                          }}</v-flex>
                        </v-layout>
                        <p
                          class="py-1 font-weight-400 custom-border-top"
                          style="white-space: pre-line"
                          v-html="row.description"
                        ></p>
                        <template v-if="row.is_optional">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                text-color="white"
                                color="orange"
                              >
                                Optional
                              </v-chip>
                            </template>
                            <span class="text-capitalize"
                              >Marked as Optional</span
                            >
                          </v-tooltip>
                        </template>
                      </v-flex>
                    </v-layout>
                    <br />
                    <template v-if="row.strike">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            class="custom-border-bottom"
                          ></span>
                        </template>
                        <span class="text-capitalize"
                          >Package {{ row.product }} is removed.</span
                        >
                      </v-tooltip>
                    </template>
                  </td>
                  <td valign="top" width="10%" align="right">
                    <p
                      class="px-2 font-weight-600 text-left custom-grey-border py-1"
                    >
                      <span class="d-flex justify-content-between">
                        <span>{{ formatNumber(row.quantity) }}</span>
                        <span class="text-capitalize">{{ row.uom }}</span>
                      </span>
                    </p>
                  </td>
                  <td valign="top" width="10%" align="right">
                    <p
                      class="px-2 font-weight-600 text-left custom-grey-border py-1"
                    >
                      <template v-if="row.is_optional == 0">{{
                        formatMoneyTwoDecimal(row.rate)
                      }}</template>
                      <template v-else>{{ formatMoneyTwoDecimal() }}</template>
                    </p>
                  </td>
                  <td valign="top" width="10%" align="right">
                    <p
                      class="px-2 font-weight-600 text-left custom-grey-border py-1"
                    >
                      <template v-if="row.is_optional == 1">{{
                        formatMoneyTwoDecimal(row.rate)
                      }}</template>
                      <template v-else>{{ formatMoneyTwoDecimal(0) }}</template>
                    </p>
                  </td>
                  <td valign="top" width="10%" align="right">
                    <p
                      class="px-2 font-weight-600 text-left custom-grey-border py-1"
                    >
                      {{ formatMoneyTwoDecimal(row.total) }}
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <table
            v-if="quotation_detail.services.length"
            width="100%"
            class="package-approval-line-items"
          >
            <thead>
              <tr>
                <th height="40px" width="60%">
                  <p class="m-0 py-1 px-2 custom-grey-border">
                    Additional Work ({{ quotation_detail.services.length }})
                  </p>
                </th>
                <th height="40px" width="10%" class="" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Quantity</p>
                </th>
                <th height="40px" width="10%" class="" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Rate</p>
                </th>
                <th height="40px" width="10%" class="" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Optional</p>
                </th>
                <th height="40px" width="10%" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Total</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, index) in quotation_detail.services">
                <tr
                  :key="index"
                  :class="{
                    'is-strike': row.strike,
                    'quote-pre-alternate': index % 2 == 0,
                    'quote-pre-alternate-one': index % 2 != 0,
                  }"
                >
                  <td width="60%">
                    <table
                      width="100%"
                      style="table-layout: fixed"
                      class="custom-grey-border"
                    >
                      <tr>
                        <td align="center" width="80px">
                          <label class="py-1 my-0 font-weight-600 font-size-13">
                            VO {{ index + 1 }}.
                          </label>
                        </td>
                        <td>
                          <label class="py-1 my-0 font-weight-600 font-size-13">
                            <span class="new-add-on" v-if="row.new_item"
                              >NEW ADD-ON</span
                            >
                            <span class="new-optional" v-if="row.is_optional"
                              >OPTIONAL</span
                            >
                            <span class="new-removed" v-if="row.strike"
                              >REMOVED</span
                            >
                            {{ row.product }}
                          </label>
                          <p
                            class="py-1 font-weight-400 custom-border-top mb-1"
                            style="white-space: pre-line"
                          >
                            {{ row.description }}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td valign="top" width="10%" align="right"></td>
                  <td valign="top" width="10%" align="right"></td>
                  <td valign="top" width="10%" align="right"></td>
                  <td valign="top" width="10%" align="right"></td>
                </tr>
                <template v-if="row.child_items">
                  <template v-for="(crow, cindex) in row.child_items">
                    <tr
                      v-if="crow.show_label"
                      :key="`tbl-label-${index}-${cindex}`"
                      :class="{
                        'quote-pre-alternate': index % 2 == 0,
                        'quote-pre-alternate-one': index % 2 != 0,
                      }"
                    >
                      <td valign="top" width="60%">
                        <table
                          width="100%"
                          style="table-layout: fixed"
                          class="border-left border-right"
                        >
                          <tr>
                            <td width="80px" align="center" valign="top"></td>
                            <td align="center">
                              <label
                                class="py-1 my-0 font-weight-600 font-size-13"
                              >
                                {{ crow.label }}
                              </label>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td valign="top" width="10%" align="right"></td>
                      <td valign="top" width="10%" align="right"></td>
                      <td valign="top" width="10%" align="right"></td>
                      <td valign="top" width="10%" align="right"></td>
                    </tr>
                    <tr
                      :key="`tbl-crow-${index}-${cindex}`"
                      :class="{
                        'quote-pre-alternate': index % 2 == 0,
                        'quote-pre-alternate-one': index % 2 != 0,
                      }"
                    >
                      <td valign="top" width="60%">
                        <table
                          width="100%"
                          style="table-layout: fixed"
                          class="border-top border-right border-left"
                        >
                          <tr>
                            <td align="center" width="80px">
                              <label
                                class="py-0 my-0 font-weight-600 font-size-13"
                              >
                                {{ index + 1 }}.{{ cindex + 1 }}.
                              </label>
                            </td>
                            <td>
                              <label
                                class="py-0 my-0 font-weight-600 font-size-13"
                              >
                                <span class="new-add-on" v-if="crow.new_item"
                                  >NEW ADD-ON</span
                                >
                                <span
                                  class="new-optional"
                                  v-if="crow.is_optional"
                                  >OPTIONAL</span
                                >
                                <span class="new-removed" v-if="crow.strike"
                                  >REMOVED</span
                                >
                                {{ crow.product_name }}
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td align="center" width="80px"></td>
                            <td>
                              <p
                                class="font-size-13 py-1 my-0 font-weight-400"
                                style="white-space: pre-line"
                              >
                                <span v-html="crow.description"></span>
                              </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td valign="top" width="10%" align="right">
                        <p
                          class="font-size-13 px-2 font-weight-600 text-left custom-grey-border py-1"
                        >
                          <span class="d-flex justify-content-between">
                            <span>{{ formatNumber(crow.quantity) }}</span>
                            <span class="text-capitalize">{{ crow.uom }}</span>
                          </span>
                        </p>
                      </td>
                      <td valign="top" width="10%" align="right">
                        <p
                          style="min-height: 35px"
                          class="font-size-13 px-2 font-weight-600 text-left custom-grey-border py-1"
                        >
                          <span v-if="!crow.is_optional">{{
                            formatMoneyTwoDecimal(crow.rate)
                          }}</span>
                        </p>
                      </td>
                      <td valign="top" width="10%" align="right">
                        <p
                          style="min-height: 35px"
                          class="font-size-13 px-2 font-weight-600 text-left custom-grey-border py-1"
                        >
                          <span v-if="crow.is_optional">{{
                            formatMoneyTwoDecimal(crow.rate)
                          }}</span>
                        </p>
                      </td>
                      <td valign="top" width="10%" align="right">
                        <p
                          class="font-size-13 px-2 font-weight-600 text-left custom-grey-border py-1"
                        >
                          {{ formatMoneyTwoDecimal(crow.total) }}
                        </p>
                      </td>
                    </tr>
                    <template v-if="crow.items">
                      <template v-for="(clrow, clindex) in crow.items">
                        <tr
                          :key="`tbl-crow-${index}-${cindex}-${clindex}`"
                          :class="{
                            'quote-pre-alternate': index % 2 == 0,
                            'quote-pre-alternate-one': index % 2 != 0,
                          }"
                        >
                          <td valign="top" width="60%">
                            <table
                              width="100%"
                              style="table-layout: fixed"
                              class="border-top border-bottom border-right border-left"
                            >
                              <tr>
                                <td align="center" width="80px"></td>
                                <td>
                                  <p
                                    class="font-size-13 py-1 my-0 font-weight-400"
                                    style="white-space: pre-line"
                                  >
                                    <span
                                      class="new-add-on"
                                      v-if="clrow.new_item"
                                      >NEW ADD-ON</span
                                    >
                                    <span
                                      class="new-optional"
                                      v-if="clrow.is_optional"
                                      >OPTIONAL</span
                                    >
                                    <span
                                      class="new-removed"
                                      v-if="clrow.strike"
                                      >REMOVED</span
                                    >
                                    <span v-html="clrow.description"></span>
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td valign="top" width="10%" align="right">
                            <p
                              class="my-0 font-size-13 px-2 font-weight-600 text-left custom-grey-border py-1"
                            >
                              <span class="d-flex justify-content-between">
                                <span>{{ formatNumber(clrow.quantity) }}</span>
                                <span class="text-capitalize">{{
                                  clrow.uom
                                }}</span>
                              </span>
                            </p>
                          </td>
                          <td valign="top" width="10%" align="right">
                            <p
                              style="min-height: 35px"
                              class="my-0 font-size-13 px-2 font-weight-600 text-left custom-grey-border py-1"
                            >
                              <span v-if="!clrow.is_optional">{{
                                formatMoneyTwoDecimal(clrow.rate)
                              }}</span>
                            </p>
                          </td>
                          <td valign="top" width="10%" align="right">
                            <p
                              style="min-height: 35px"
                              class="my-0 font-size-13 px-2 font-weight-600 text-left custom-grey-border py-1"
                            >
                              <span v-if="clrow.is_optional">{{
                                formatMoneyTwoDecimal(clrow.rate)
                              }}</span>
                            </p>
                          </td>
                          <td valign="top" width="10%" align="right">
                            <p
                              class="my-0 font-size-13 px-2 font-weight-600 text-left custom-grey-border py-1"
                            >
                              {{ formatMoneyTwoDecimal(clrow.total) }}
                            </p>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </template>
                </template>
                <tr :key="`tbl-blank-${index}`">
                  <td colspan="5" height="20px"></td>
                </tr>
              </template>
            </tbody>
          </table>
          <br />
          <table width="100%">
            <tr>
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0">Sub Total</label>
              </td>
              <td height="30px" align="center" width="15%"></td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoneyTwoDecimal(quotation_detail.quotation.sub_total)
                }}</label>
              </td>
            </tr>
            <tr v-if="quotation_detail.quotation.optional_total">
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0">Optional Sub Total</label>
              </td>
              <td height="30px" align="center" width="15%"></td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoneyTwoDecimal(
                    quotation_detail.quotation.optional_total
                  )
                }}</label>
              </td>
            </tr>
            <tr v-if="quotation_detail.quotation.discount_amount">
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0">Discount </label>
              </td>
              <td height="30px" align="center" width="15%"></td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoneyTwoDecimal(
                    quotation_detail.quotation.discount_amount
                  )
                }}</label>
              </td>
            </tr>
            <tr v-if="quotation_detail.quotation.tax_amount">
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0"
                  >Tax [{{
                    formatNumber(quotation_detail.quotation.tax_value)
                  }}%]</label
                >
              </td>
              <td height="30px" align="center" width="15%">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <label
                      v-bind="attrs"
                      v-on="on"
                      class="cursor-pointer font-weight-600 m-0"
                      >{{
                        formatMoneyTwoDecimal(
                          quotation_detail.quotation.taxable_amount
                        )
                      }}</label
                    >
                  </template>
                  <span class="text-capitalize">Taxable Amount</span>
                </v-tooltip>
              </td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoneyTwoDecimal(quotation_detail.quotation.tax_amount)
                }}</label>
              </td>
            </tr>
            <tr v-if="quotation_detail.quotation.adjustment != 0">
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0">Adjustment</label>
              </td>
              <td height="30px" align="center" width="15%"></td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoneyTwoDecimal(quotation_detail.quotation.adjustment)
                }}</label>
              </td>
            </tr>
            <tr>
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0">Grand Total</label>
              </td>
              <td height="30px" align="center" width="15%"></td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoneyTwoDecimal(quotation_detail.quotation.total)
                }}</label>
              </td>
            </tr>
          </table>
          <br />
          <table width="100%">
            <tbody>
              <tr>
                <td colspan="10" height="10px"></td>
              </tr>
              <template v-if="quotation_detail.quotation.client_remark">
                <tr>
                  <td
                    height="30px"
                    colspan="10"
                    align="left"
                    valign="center"
                    class="customer-font-bold pl-4"
                  >
                    Notes:
                  </td>
                </tr>
                <tr>
                  <td colspan="10" align="left" valign="center" class="pl-4">
                    <div
                      class="pre-formatted"
                      v-html="quotation_detail.quotation.client_remark"
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td colspan="10" height="10px"></td>
                </tr>
              </template>
              <template v-if="false">
                <tr>
                  <td
                    height="30px"
                    colspan="10"
                    align="left"
                    valign="center"
                    class="customer-font-bold pl-4"
                  >
                    Terms and Conditions:
                  </td>
                </tr>
                <tr v-if="quotation_detail.quotation.term_conditions">
                  <td colspan="10" align="left" valign="center">
                    <div
                      class="pre-formatted pl-4"
                      v-html="quotation_detail.quotation.term_conditions"
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td
                    height="30px"
                    colspan="10"
                    align="left"
                    valign="center"
                    class="pl-4"
                  >
                    We trust above is in good order. Should you have any
                    clarifications, please do not hesitate to contact us.
                  </td>
                  <!-- Fixed Line For Every Quotation -->
                </tr>
              </template>
              <tr>
                <td colspan="10" height="10px"></td>
              </tr>
            </tbody>
          </table>
          <table width="100%" style="table-layout: fixed">
            <tbody>
              <tr class="signature-pad">
                <td colspan="5" width="50%" align="left" valign="center">
                  <div id="id-signature-pad" style="width: 400px">
                    <!--      <v-layout> -->
                    <v-flex class="d-flex">
                      <span
                        >Designer &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;:<span
                          class="font-weight-600 ml-2 text-capitalize"
                          >{{ quotation_detail.prepared_by.full_name }}</span
                        ></span
                      >
                    </v-flex>

                    <v-flex class="d-flex">
                      <span
                        >Designation &nbsp;:<span
                          class="font-weight-600 ml-2 text-capitalize"
                          >{{ quotation_detail.prepared_by.designation }}</span
                        ></span
                      >
                    </v-flex>
                    <!--   </v-layout> -->
                    <div
                      ref="id_signature_div"
                      style="width: 100px"
                      class="custom-border-grey-dashed"
                    >
                      <canvas ref="id_signature"></canvas>
                    </div>
                    <v-layout>
                      <v-flex class="text-left">{{
                        quotation_detail.quotation.sales
                      }}</v-flex>
                      <v-flex class="text-right">
                        <v-btn
                          content="Click here to clear signature"
                          v-tippy="{ arrow: true, placement: 'top' }"
                          icon
                          small
                          v-on:click="id_signature.clear()"
                        >
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </td>
                <td colspan="5" width="50%" align="right" valign="center">
                  <div class="customer-signature-pad" style="width: 400px">
                    <v-layout>
                      <v-flex class="text-left"
                        >Customer Acceptance:<br /><span
                          class="font-weight-600"
                          v-if="quotation_detail.customer"
                          >{{ quotation_detail.customer.company_name }}</span
                        ><span v-else><br /></span
                      ></v-flex>
                    </v-layout>
                    <div
                      ref="customer_signature_div"
                      style="width: 100px"
                      class="custom-border-grey-dashed"
                    >
                      <canvas ref="customer_signature"></canvas>
                    </div>
                    <v-layout>
                      <v-flex class="text-left"
                        >Authorized Signature<br />Date:
                        <span class="font-weight-600">{{
                          today_date
                        }}</span></v-flex
                      >
                      <v-flex class="text-right">
                        <v-btn
                          content="Click here to clear signature"
                          v-tippy="{ arrow: true, placement: 'top' }"
                          icon
                          small
                          v-on:click="customer_signature.clear()"
                        >
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="10" height="50px"></td>
              </tr>
              <tr>
                <td colspan="10" height="50px" align="right" valign="center">
                  <v-btn
                    :disabled="validSignature || formLoading || pageLoading"
                    :loading="formLoading"
                    class="mr-2 custom-grey-border custom-bold-button white--text"
                    color="cyan"
                    v-on:click="submitSignature"
                  >
                    Accept
                  </v-btn>
                  <v-btn
                    :disabled="formLoading || pageLoading"
                    :loading="formLoading"
                    class="ml-2 custom-grey-border custom-bold-button white--text"
                    color="red lighten-1"
                    v-on:click="rejectQuotationDialog = true"
                  >
                    Reject
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
          <table v-if="false" width="100%">
            <tfoot>
              <tr>
                <td
                  height="30px"
                  colspan="10"
                  align="center"
                  valign="center"
                  class="custom-border-top"
                  style="font-size: 10px"
                >
                  {{ company_detail.street_1 }}, {{ company_detail.street_2 }},
                  {{ company_detail.landmark }}
                  {{ company_detail.zip_code }} Tel:
                  {{ company_detail.phone_number }} •
                  {{ company_detail.website_url }} •
                  {{ company_detail.email_address }}
                </td>
              </tr>
            </tfoot>
          </table>
        </v-col>
      </v-row>
    </v-container>
    <Dialog :common-dialog="rejectQuotationDialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <div>
          <p class="m-0">Are you sure, you want to reject this package?</p>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="red lighten-1"
          v-on:click="rejectQuotation()"
        >
          Yes! Reject
        </v-btn>
        <v-btn
          :disabled="formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="rejectQuotationDialog = false"
        >
          No! Cancel
        </v-btn>
      </template>
    </Dialog>
    <template v-if="validationErrorBag.length">
      <v-snackbar
        :timeout="5000"
        :value="true"
        top
        color="error"
        right
        v-for="(error, index) in validationErrorBag"
        :key="index"
      >
        {{ error }}
      </v-snackbar>
    </template>
  </v-app>
</template>

<style type="text/css" scoped>
.quotation {
  font-weight: 600;
  text-decoration: underline;
}
.customer-font-bold {
  font-weight: 600;
}
.customer-border-top {
  border-top: solid 2px #000;
}
.customer-border-bottom {
  border-bottom: solid 2px #000;
}
.customer-border-right {
  border-right: solid 2px #000;
}
.customer-border-left {
  border-left: solid 2px #000;
}
.line-items td {
  padding: 4px;
}
table tbody td:first-child {
  width: 70px !important;
}
table tbody td:nth-child(3) {
  width: 100px !important;
}
table > tbody > tr.signature-pad > td {
  width: 50% !important;
}

.v-snack__content {
  font-size: 20px !important;
}

.quotation-approved {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;
}

.quotation-approved h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.quotation-approved p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
.quotation-approved i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.quotation-approved .card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
.new-add-on {
  border: solid 1px #328507;
  border-radius: 5px;
  padding: 0 5px;
  color: #328507;
  font-weight: 600;
}
.new-optional {
  border: solid 1px #ff9800;
  border-radius: 5px;
  padding: 0 5px;
  color: #ff9800;
  font-weight: 600;
}
.new-removed {
  border: solid 1px #ed1c24;
  border-radius: 5px;
  padding: 0 5px;
  color: #ed1c24;
  font-weight: 600;
}
.quote-pre-alternate {
  background-color: #fff4be5e;
  border: solid 1px #e2e5ec;
}
.quote-pre-alternate-one {
  background-color: #bfdfff24;
  border: solid 1px #e2e5ec;
}
</style>

<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import {
  PATCH,
  VERIFY_QUOTATION_CUSTOMER,
} from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin.js";
import SignaturePad from "signature_pad";
import ObjectPath from "object-path";

export default {
  name: "quotation-approve",
  mixins: [ValidationMixin, CommonMixin],
  data: () => {
    return {
      owner_name: null,
      block: null,
      street: null,
      unit: null,
      postal_code: null,
      contact_no: null,
      contact_alt_no: null,
      dialog: true,
      formValid: true,
      formLoading: false,
      rejectQuotationDialog: false,
      isLoaded: false,
      isSubmitted: false,
      isRejected: false,
      pageLoading: false,
      tokenNotFound: false,
      verificationCode: null,
      securityToken: null,
      today_date: null,
      customer_signature: null,
      id_signature: null,
      validationErrorBag: new Array(),
      quotation_detail: new Object(),
      company_detail: new Object(),
      header_footer: new Object(),
      sales_signature: new Object(),
    };
  },
  components: {
    Dialog,
  },
  methods: {
    rejectQuotation() {
      this.formLoading = true;
      const quotation_id = ObjectPath.get(
        this.quotation_detail,
        "quotation.id",
        0
      );
      this.$store
        .dispatch(PATCH, {
          url: `pre-quotation/${quotation_id}/reject?token=${this.securityToken}`,
        })
        .then(() => {
          this.isRejected = true;
          this.rejectQuotationDialog = false;
        })
        .catch((error) => {
          const errors = ObjectPath.get(error, "data.message", null);
          if (errors) {
            this.validationErrorBag = errors.split("<br>");
          } else {
            this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    getSubTotalAmount(sub_total, discount) {
      return this.formatMoneyTwoDecimal(this.lodash.sum([sub_total, discount]));
    },
    submitSignature() {
      const _this = this;

      if (_this.customer_signature.isEmpty()) {
        return false;
      }

      _this.formLoading = true;
      _this.isSubmitted = false;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(VERIFY_QUOTATION_CUSTOMER, {
          url: "pre-quotation/customer/submit",
          data: {
            customer: _this.customer_signature.toDataURL(),
            id: _this.id_signature.toDataURL(),
            code: _this.verificationCode,
          },
          token: _this.securityToken,
        })
        .then(() => {
          _this.isSubmitted = true;
          setTimeout(function () {
            window.close();
          }, 2000);
        })
        .catch((error) => {
          const errors = ObjectPath.get(error, "response.data.message", null);
          if (errors) {
            _this.validationErrorBag = errors.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    verifyCustomer() {
      const _this = this;

      _this.formLoading = true;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(VERIFY_QUOTATION_CUSTOMER, {
          url: "pre-quotation/verify",
          token: _this.securityToken,
        })
        .then((response) => {
          _this.quotation_detail = ObjectPath.get(response, "data.detail", {});
          _this.company_detail = ObjectPath.get(response, "data.company", {});

          _this.owner_name = ObjectPath.get(
            response,
            "data.detail.owner_name",
            null
          );
          _this.block = ObjectPath.get(response, "data.detail.address_1", null);
          _this.street = ObjectPath.get(
            response,
            "data.detail.address_2",
            null
          );
          _this.unit = ObjectPath.get(response, "data.detail.unit_no", null);
          _this.postal_code = ObjectPath.get(
            response,
            "data.detail.postal_code",
            null
          );
          _this.contact_no = ObjectPath.get(
            response,
            "data.detail.phone_number",
            null
          );
          console.log(response);
          _this.contact_alt_no = ObjectPath.get(
            response,
            "data.detail.alt_phone_number",
            null
          );

          _this.header_footer = ObjectPath.get(
            response,
            "data.header_footer.quotation_header",
            null
          );
          _this.sales_signature = ObjectPath.get(
            response,
            "data.sales_signature",
            null
          );
          _this.today_date = ObjectPath.get(response, "data.today_date", null);
          _this.dialog = false;
          _this.isLoaded = true;
          _this.$nextTick(() => {
            _this.initSignature();
          });
        })
        .catch((error) => {
          setTimeout(function () {
            window.close();
          }, 1000);
          const errors = ObjectPath.get(error, "response.data.message", null);
          if (errors) {
            _this.validationErrorBag = errors.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    initSignature() {
      const _this = this;
      _this.$nextTick(() => {
        let ccanvas = _this.$refs["customer_signature"];
        let ccanvasDiv = _this.$refs["customer_signature_div"];
        // let cparentDiv = document.getElementById("customer-signature-pad");
        let cparentWidth = 400;
        ccanvas.setAttribute("width", cparentWidth);
        ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");
        _this.customer_signature = new SignaturePad(ccanvas);
      });
      _this.$nextTick(() => {
        let ccanvas = _this.$refs["id_signature"];
        let ccanvasDiv = _this.$refs["id_signature_div"];
        // let cparentDiv = document.getElementById("id-signature-pad");
        let cparentWidth = 400;
        ccanvas.setAttribute("width", cparentWidth);
        ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");
        _this.id_signature = new SignaturePad(ccanvas);
      });
    },
  },
  mounted() {
    this.verifyCustomer();
  },
  created() {
    this.securityToken = ObjectPath.get(this.$route, "query.token", null);

    const main_body = document.getElementById("main-app-body-ace-interior");
    if (main_body) {
      main_body.removeAttribute("style");
    }

    if (!this.securityToken) {
      this.dialog = false;
      this.tokenNotFound = true;
    }
  },
  computed: {
    validSignature() {
      return this.customer_signature
        ? this.customer_signature.isEmpty()
        : false;
    },
  },
};
</script>
